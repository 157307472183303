import React from 'react'
import PropTypes from 'prop-types'
import { Link, graphql } from 'gatsby'
import styled from 'styled-components'
import kebabCase from 'lodash/kebabCase'
import MDXRenderer from 'gatsby-mdx/mdx-renderer'
import { Layout, Wrapper, Header, Subline, SEO, PrevNext } from '../components'
import config from '../../config'

const Content = styled.article`
  grid-column: 2;
  box-shadow: 0 40px 120px rgba(0, 0, 0, 0.75);
  max-width: 1000px;
  border-radius: 0.25rem;
  margin: 1rem 0;
  padding: 0 2rem 2rem 2rem;
  background-color: ${props => props.theme.colors.white};
  z-index: 9000;

  p {
    font-size: 1.1rem;
    letter-spacing: -0.003em;
    line-height: 1.58;
    --baseline-multiplier: 0.179;
    --x-height-multiplier: 0.35;
    @media (max-width: ${props => props.theme.breakpoints.phone}) {
      font-size: 1rem;
    }
  }

  blockquote {
    margin: 2.5rem 5rem;
  }

  ol {
    margin: 2rem 0;

    li {
      margin: 1rem 0;
    }
  }

  .footnotes {
    padding: 0.75rem;
    border-radius: 0.25rem;
    margin: 3rem 0 1rem;
    background-color: whitesmoke;
    font-size: 16px;

    &:before {
      content: 'footnotes';
      text-transform: uppercase;
      font-size: 12px;
      font-family: sans-serif;
    }
    .footnote-backref {
      margin-left: 1rem;
    }
  }
`

const Title = styled.h1`
  margin-bottom: 1rem;
  margin-top: 2rem;
`

const PostContent = styled.div`
  margin-top: 2rem;
`

const Post = ({ pageContext: { slug, prev, next }, data: { mdx: postNode } }) => {
  const post = postNode.frontmatter

  return (
    <Layout customSEO>
      <Wrapper>
        <SEO postPath={slug} postNode={postNode} article />
        <Header>
          <Link to="/">{config.siteTitle}</Link>
        </Header>
        <Content>
          <Subline>
            {post.date} &mdash; {postNode.timeToRead} Min Read &mdash; In{' '}
            {post.categories.map((cat, i) => (
              <React.Fragment key={cat}>
                {!!i && ', '}
                <Link to={`/categories/${kebabCase(cat)}`}>{cat}</Link>
              </React.Fragment>
            ))}
          </Subline>
          <Title>{post.title}</Title>
          <PostContent>
            <MDXRenderer>{postNode.code.body}</MDXRenderer>
          </PostContent>
          <PrevNext prev={prev} next={next} />
        </Content>
      </Wrapper>
    </Layout>
  )
}

export default Post

Post.propTypes = {
  pageContext: PropTypes.shape({
    slug: PropTypes.string.isRequired,
    next: PropTypes.object,
    prev: PropTypes.object,
  }),
  data: PropTypes.shape({
    mdx: PropTypes.object.isRequired,
  }).isRequired,
}

Post.defaultProps = {
  pageContext: PropTypes.shape({
    next: null,
    prev: null,
  }),
}

export const postQuery = graphql`
  query postBySlug($slug: String!) {
    mdx(fields: { slug: { eq: $slug } }) {
      code {
        body
      }
      excerpt
      frontmatter {
        title
        date(formatString: "MM/DD/YYYY")
        categories
      }
      timeToRead
      parent {
        ... on File {
          mtime
          birthtime
        }
      }
    }
  }
`
